@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "~react-phone-number-input/style.css";

.phone-intl-input {
	margin-bottom: 15px;
	position: relative;

	& .PhoneInput {
		&Country {
			position: absolute;
			z-index: 1;
			box-sizing: border-box;
			top: 0;
			bottom: 0;
			left: 0;
			padding-left: 17px;
		}

		&CountryIcon--border {
			background-color: white;
			box-shadow: none;
			outline: 0;
			border-right: 1px solid $grey-medium;
			padding-right: 19px;
		}

		&CountrySelect {
			font-size: 1.4rem;

			&:focus {
				outline: none;

				+ .PhoneInputCountryIcon--border {
					box-shadow: none !important;
				}
			}
		}

		&CountrySelectArrow {
			--PhoneInputCountrySelectArrow-color: black;
			position: absolute;
			right: 8px;
		}
	}

	.control-group {
		width: 100%;
	}

	:not(.control-group--touched) > .control-group__label {
		padding-left: 50px;
	}

	input {
		padding-left: 63px;
		padding-top: 0;
	}
}
